body {
  overflow-x: hidden;
  min-height: 100vh;
  width: 100vw;
}

pre {
 white-space: pre-wrap;
 white-space: -moz-pre-wrap;
 white-space: -pre-wrap;
 white-space: -o-pre-wrap;
 word-wrap: break-word;
}

label [aria-hidden="true"] {
  border: 1px solid #cbd5e0 !important;
}

div [aria-hidden="true"] {
  border: inherit;
}

div [type="radio"] > span {
  background-color: white;
}

.no-current-page[aria-current="page"] {
  font-weight: inherit !important;
  color: inherit !important;
}

.proposal-description > *:not(:last-child) {
  margin-bottom: 1em;
}

.proposal-description > * {
  font-family: "DM Sans";
  word-break: break-word;
}

/* progress bar */
.gray-bar {
  background-color: #718096;
}

.gray-bar > div {
  background-color: #718096 !important;
}

.dual-bar {
  background-color: #f44061 !important;
}

.dual-bar > div {
  background-color: #25c9a1 !important;
}

.in-favor-bar {
  background-color: transparent !important;
}

.in-favor-bar > div {
  background-color: #00e6cd !important;
}

.against-bar {
  background-color: transparent !important;
}

.against-bar > div {
  background-color: #cbd5e0 !important;
}

/* clamp text */
.clamp-text-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.clamp-text-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clamp-text-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

body::-webkit-scrollbar {
  border-top-right-radius: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-bottom-right-radius: 1rem;
}

body::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #718096;
}

/* scrollbar */
.scrollbar::-webkit-scrollbar {
  border-top-right-radius: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-bottom-right-radius: 1rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #718096;
}

.dropshadow {
  filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1));
}

[aria-current="page"] {
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  color: #4a5568 !important;
}

.card-paginator {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
}

.card-paginator svg {
  color: #a0aec0;
}

.card-paginator li.previous {
  position: absolute;
  left: 0;
}

.card-paginator li.next {
  position: absolute;
  right: 0;
}

.card-paginator-link--active {
  color: #171923;
}

.card-paginator-link {
  color: #a0aec0;
  margin-right: 0.5rem;
}

.card-paginator li:nth-last-of-type(2) a {
  margin-right: 0;
}

label [aria-hidden="true"] {
  border: 1px solid black;
}

@keyframes hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
@keyframes up-and-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.3em);
  }
  100% {
    transform: translateY(0);
  }
}

.custom-line-chart-tooltip {
  background: #fff;
  opacity: 0.9;
  box-shadow: 0px 4px 14px rgba(159, 162, 180, 0.2);
  border-radius: 4px;
}

.no-underline:hover {
  text-decoration: none !important;
}

.no-box-shadow:focus {
  box-shadow: none !important;
}

*[data-hover] {
  border: inherit !important;
}

dd {
  display: flex;
  align-items: center;
}
dd > *:not(:last-child) {
  margin-right: 0.5rem;
}

.no-margin {
  margin: 0 !important;
}

.cryptex-icon-cls-1 {
  fill: #1d1d3c;
}
.cryptex-icon-cls-2 {
  fill: url(#linear-gradient);
}
.cryptex-icon-cls-3 {
  fill: #fff;
}
.cryptex-icon-cls-4 {
  fill: #a440f2;
}
.cryptex-icon-cls-5 {
  opacity: 0.4;
  fill: url(#linear-gradient-2);
}
.cryptex-icon-cls-6 {
  fill: url(#linear-gradient-3);
}

.conjure-icon-1 {
  fill: #fdc433;
}
.conjure-icon-2 {
  fill: #03b6c2;
}
.conjure-icon-3 {
  fill: #fe6d6f;
}

.rari-cls-1 {
  stroke: #000;
}
.rari-cls-1,
.rari-cls-2,
.rari-cls-3,
.rari-cls-4 {
  stroke-miterlimit: 10;
}
.rari-cls-2,
.rari-cls-3,
.rari-cls-4 {
  stroke-width: 60px;
}
.rari-cls-2 {
  stroke: url(#White_Black);
}
.rari-cls-3 {
  stroke: url(#White_Black-2);
}
.rari-cls-4 {
  stroke: url(#White_Black-3);
}

.ProseMirror {
  outline: none;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.ProseMirror p.is-editor-empty:first-child::after {
  content: "It can be multi-line!";
  float: left;
  color: #adb5bd;
  pointer-events: none;
}

.ProseMirror ul, .ProseMirror ol {
  margin-left: 18px;
}

.ProseMirror ul ul, .ProseMirror ol ol {
  margin-left: 18px;
}

.uppy-DragDrop-inner svg {
  display: none;
}

.uppy-DragDrop-label {
  font-size: 20px;
  font-weight: "semibold";
}

.uppy-DragDrop-note {
  font-size: 12px;
}


/* react-md-editor */
.w-md-editor-toolbar {
  height: 60px !important;
  border: 0 !important;
}

.w-md-editor-bar {
  display: none !important;
}

.w-md-editor-show-edit {
  height: 375px !important;
  border: 0 !important;
}

.w-md-editor-text {
  border: 1px solid rgb(235, 240, 246) 
}

.w-md-editor-show-edit {
  box-shadow: none !important;
}

.w-md-editor .title {
  line-height: 10px !important;
  font-size: unset !important;
  font-weight: unset !important;
}

.w-md-editor-text-input {
  overflow: hidden;
  -webkit-text-fill-color: transparent;
}

.w-md-editor-toolbar {
  padding-left: 0 !important;
}

.w-md-editor-toolbar li > button {
  padding: 8px !important;
  margin-right: 5px !important;
  height: 42px !important;
  width: 40px !important;
  background-color: #EDF2F6 !important;
}

.w-md-editor-toolbar li > button:hover {
  background-color: #dfe5e9 !important;
}



/* animations */
@keyframes gradient {
  0% {
    background-position: top left;
  }
  50% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.waving-gradient {
  background: linear-gradient(-45deg, #FFEEF8, #F1F8FF, #FFEEF8, #F1F8FF);
  background-size: 300% 300%;
  animation: gradient 5s ease infinite;
}

.rotation {
  animation: rotation 3s ease infinite;
}

.BeaconFabButtonFrame {
  display: block;
}

@media (max-width: 30em) {
  .BeaconFabButtonFrame {
    display: none;
  }
}

.radius-md {
  border-radius: 0.375rem !important;
}

.gray-card-onboarding-color {
  color: #2D3748 !important;
}

.no-top-margin {
  margin-top: unset !important;
}

.no-left-margin {
  margin-left: unset !important;
}

.fill-color {
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.background-clip-text {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.home-border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(to left, hsla(255, 66%, 87%, 1), hsla(255, 100%, 95%, 1));
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.home-table-mask {
  -webkit-mask: linear-gradient(to bottom, black 50%, transparent);
}